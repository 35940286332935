@import './fonts/firasans.css';

body, html{
  margin: 0;
  font-family: 'Fira Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Verdana,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-weight: normal;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainContainer{
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
}

.content{
  max-width: 800px;
  height: 100%;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  width: 100%;
}

.sendMessageForm{
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
}

.title{
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.line{
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.lineOrder{
  background: #eee;
  border-radius: 10px;
  line-height: 40px;
  font-weight: 600;
  color: #5364FF;
}

.lineLeft{
  text-align: start;
  padding-left: 10px;
}

.lineFlex{
  display: flex;
}

.lineBigMargin{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
}

div.leftSide{
  width: 280px;
}

.leftSide{
  font-weight: 600;
  margin-right: 20px;
}

input[type=time] {
  font-size: 18px;
  width: 100px;
  text-align: center;
  border: 1px solid #666;
  border-radius: 5px;
  background: #fff;
}

.buttonAndNote span{
  text-align: center;
}

.buttonAndNote{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.button{
  padding: 12px 20px;
  background: #5364FF;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.button-green{
  background: #00BB40;
  text-transform: unset;
}

.disabled{
  background: #eeeeee;
  color: #999;
}

.data{
  padding: 5px 10px;
  border-radius: 10px;
  background:  #eee;
}

.popupForm{
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border: 1px solid #eee;
  z-index: 200;
  top: 300px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 30%);
}
.popupForm .lineFlex{
  justify-content: center;
}
input[type=file]{
  display: none;
}
input[type=number]{
  padding: 10px 15px;
  background: #FFFFFF;
  border: 1px solid #C6CAD2;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  width: 240px;
}

.width200px{
  width: 200px;
}

.closeContainer{
  display: flex;
  justify-content: flex-end;
}

.closeButton{
  display: block;
  margin-top: -20px;
  margin-right: -10px;
  background: #eee;
  width: fit-content;
  text-align: center;
  border-radius: 33px;
  padding: 5px 12px;
  border: 1px solid #e0e0e0;
  font-weight: 600;
  color: #555;
  font-size: 15px;
  text-transform: lowercase;
}

.note{
  font-size: 14px;
  margin-top: 5px;
  font-style: italic;
}

.chequeRow{
  background: #fafafa;
  font-weight: 500;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #999;
  border-radius: 7px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chequeRow .closeButton{
  margin-top: 0;
  margin-right: 0;
  padding: 5px 12px
}

.messageInfo{
  padding:  20px;
  text-align: center;
  font-size: 18px;
  background: #fafafa;
  margin: 100px 20px;
  border-radius: 10px;
  box-shadow: 3px 3px 5px #eee;
  border: 2px solid #fdc0c0;
  color: #555;
  font-weight: 400;
  line-height: 28px;
}

.loading{
  border-color: #828dff;
}

.loadingText{
  font-size: 14px;
  font-style: italic;
  color: #828dff;
  letter-spacing: 2px;
}

.messageGood{
  border-color: #92d692;
}
